import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal" ]
  static classes = [ "active" ]
  
  show(event) {
    const targetElement = this.#getModalTargetFromEvent(event)

    if (targetElement) {
      this.openModal = targetElement
      targetElement.classList.add(this.activeClass)
    }
  }
  
  hide(event) {
    const targetElement = this.#getModalTargetFromEvent(event) || this.openModal

    if (targetElement) {
      console.log('target element', targetElement)
      targetElement.classList.remove(this.activeClass)
      this.openModal = null
      this.dispatch("close", { target: targetElement })
    }
  }

  // Private
  #findModalTargetWithID(id) {
    return this.modalTargets.find((target) => target.id === id)
  }

  #hasMultipleModalTargets() {
    return (this.modalTargets && this.modalTargets.length > 1)
  }

  #getModalTargetFromEvent({ params, target }) {
    if (this.#hasMultipleModalTargets()) {
      const targetID = params.id || target.value
      return this.#findModalTargetWithID(targetID)
    }

    return this.modalTarget
  }
}
